/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { headingPrimary, subtitleText } from 'src/components/common/Typography'

import { HeadlessDemoFormFields } from 'src/components/HeadlessDemoForm/HeadlessDemoFormFields'
import { mq } from 'src/utils/mq'

const wrapper = css`
  padding: 16px;
  position: relative;

  ${mq.md} {
    padding: 64px;
  }
`

const titleStyle = css`
  ${headingPrimary}
  margin-bottom: 16px;
`

const titleBlockStyles = css`
  margin: 0 0 48px;

  ${mq.md} {
    margin: 0 0 58px;
  }
`

export const HeadlessDemoFormForm: React.FC = () => {
  return (
    <div css={wrapper}>
      <div css={titleBlockStyles}>
        <h1 css={titleStyle}>
          Request a demo
        </h1>
        <p css={subtitleText}>
          We will prepare a custom demo of Headless Commerce to show and discuss possible solutions for your needs.
        </p>
      </div>
      <HeadlessDemoFormFields />
    </div>
  )
}
