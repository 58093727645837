/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { AgreeToTermsCheckbox } from 'src/components/common/AgreeToTermsCheckbox'
import { Button } from 'src/components/Button/Button'
import React from 'react'
import { SelectField } from 'src/components/common/SelectField'
import { TextField } from 'src/components/common/TextField'
import { mq } from 'src/utils/mq'
import { path } from 'src/utils/path'
import { useForm } from 'react-hook-form'

const formWrapperStyles = css`
  ${mq.md} {
    display: flex;
    justify-content: space-between;
  }
`

const formSectionStyles = css`
  ${mq.md} {
    flex-basis: 45%;
  }
`

type FormData = {
  description: string
  platform: string
  projectType: string
  name: string
  email: string
  companySize: string
  agreeToTerms: boolean
}

export const HeadlessDemoFormFields: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()
  const $form = React.useRef<HTMLFormElement>(null)

  const onSubmit = React.useCallback(
    (data: FormData) => {
      $form.current?.submit()
    },
    [$form],
  )

  return (
    <form
      action={path.thankYou}
      name="reaction-demo"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      data-netlify-honeypot="rp-demo-valid-customer"
      ref={$form}
      noValidate
    >
      <input type="hidden" name="form-name" value="reaction-demo" />
      <div css={formWrapperStyles}>
        <div css={formSectionStyles}>
          <SelectField
            title="Type of the store"
            htmlFor="demo-project-type"
            hasError={!!errors.projectType}
            defaultValue=""
            required
            {...register('projectType', { required: true })}
          >
            <option value="" disabled>
              Select option
            </option>
            <option>B2C</option>
            <option>B2B</option>
            <option>Marketplace</option>
            <option>D2C (Direct to customer)</option>
            <option>Other</option>
          </SelectField>
          <TextField
            title="Description of your project"
            htmlFor="demo-description"
            placeholder="What the features and processes do you want to implement?"
            hasError={!!errors.description}
            rows={8}
            multiline
            required
            {...register('description', { required: true })}
          />
          <TextField
            title="Do you have an existing platform?"
            htmlFor="demo-platform"
            placeholder="If so, please describe it. Do you want to replace it or extend it with headless frontend and/or integrations?"
            hasError={!!errors.platform}
            rows={6}
            multiline
            {...register('platform', { required: false })}
          />
        </div>
        <div css={formSectionStyles}>
          <TextField
            title="Person's name to contact"
            htmlFor="demo-name"
            placeholder="Your name"
            hasError={!!errors.name}
            required
            {...register('name', { required: true })}
          />
          <TextField
            title="Contact email"
            htmlFor="demo-email"
            placeholder="Your email"
            type="email"
            hasError={!!errors.email}
            required
            {...register('email', { required: true, pattern: /^.+@.+\..+$/ })}
          />
          <SelectField
            title="Size of your company"
            htmlFor="demo-size"
            hasError={!!errors.companySize}
            defaultValue=""
            required
            {...register('companySize', { required: true })}
          >
            <option value="" disabled>
              Select option
            </option>
            <option>Less than 10 employees</option>
            <option>11-30</option>
            <option>31-100</option>
            <option>More than 100 employees</option>
          </SelectField>
        </div>
      </div>
      <AgreeToTermsCheckbox
        {...register('agreeToTerms', { required: true })}
        hasError={!!errors.agreeToTerms}
      />
      <Button variant="contained" size="large" type="submit">
        Submit form
      </Button>
    </form>
  )
}
