/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { Container } from 'src/components/common/Container'
import { HeadlessDemoFormForm } from 'src/components/HeadlessDemoForm/HeadlessDemoFormForm'
import { ShadowBox } from 'src/components/common/ShadowBox'
import { WhatDoWeDoSuffix } from 'src/components/WhatDoWeDo/WhatDoWeDoSuffix'
import { mq } from 'src/utils/mq'
import { path } from 'src/utils/path'

const wrapperStyles = css`
  margin-bottom: 80px;

  ${mq.md} {
    margin-bottom: 0;
    padding: 0 16px;
  }

  ${mq.lg} {
    padding: 0 24px 0 0;
  }

  ${mq.xl} {
    padding: 0 50px 0 0;
  }
`

const suffixStyles = css`
  margin-top: -35px;
  margin-bottom: 80px;
`

export const HeadlessDemoForm: React.FC = () => {
  return (
    <Container css={wrapperStyles}>
      <ShadowBox>
        <HeadlessDemoFormForm />
      </ShadowBox>
      <div css={suffixStyles}>
        <WhatDoWeDoSuffix whatDoWeDoSuffix="Get known how we work" whatDoWeDoSuffixLink={path.howWeWork} />
      </div>
    </Container>
  )
}
